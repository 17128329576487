import { useState, useEffect, useMemo } from "react";
import Board from "./Components/Board.jsx";
import { BigNumber, ethers } from "ethers";
import { Bounce, ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
const OPTIMATES_WETH = "0x4e3073a549803f3153F174C93344aD0f51f6fAf7";
const TOKEN_NAME = "Wrapped Ether";
const OPTIMATES_ADDRESS = "0xb67Ea92BeF21528acf81EefFc4Fc646D7Af6DC30";
const PUBLIC_API_URL = "https://api.optimatesprotocol.com";
// const TEST_CONTRACT_ADDRESS = "0x0A10B46dE02a303c922d2C0cB47bFe79c073DC0f";
// const TEST_TOKEN_ADDRESS = "0xD10401Aed76F40bfe124b95A6d663567B249da7E";
// const TEST_PAYMASTER_ADDRESS = "0x0d4B07210f563780937020108a1bbA8397F5Bb9B";
const GAME_CONTRACT_ADDRESS = "0x3B642E8A9EA526e06299d06175001CCC60C3802C";

let collectInfoAbi = [
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "collectInfo",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

function App() {
  const [wallet, setWallet] = useState(null);
  const [address, setAddress] = useState("");
  const [bonusSelected, setBonusSelected] = useState(false);
  const [bonusCount, setBonusCount] = useState(0);
  const [greenCount, setGreenCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ownedBoxes, setOwnedBoxes] = useState({ green: "0", bonus: "0" });
  const [claimPhase, setClaimPhase] = useState("Claim & Play Again!");
  // const [gameSessionId, setGameSessionId] = useState("");
  const [gameSessionId, setGameSessionId] = useState("");
  // Game States :
  // 0 : Not started
  // 1 : Started
  // 2 : Ended
  const [gameState, setGameState] = useState(0);
  const [chainId, setChainId] = useState (null);


  useEffect(() => {
    //@ts-ignore
    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    })

    const getChainId = async () => {
      try {
        //@ts-ignore
        const id = await window.ethereum.request({ method: 'eth_chainId' });
        setChainId(parseInt(id, 16));

      } catch (error) {
        console.error('Error getting chain ID:', error);
      }
    };

    getChainId();


  }, [wallet]);

  const handleConnectWallet = async () => {
    // @ts-ignore
    if (window?.ethereum === undefined) {
      toast.error("Please connect wallet !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    try {
      // @ts-ignore
      const ethereum = window.ethereum;

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      let provider = new ethers.providers.Web3Provider(ethereum);
      let signer = await provider.getSigner();
      let address = await signer.getAddress();
      let contract = new ethers.Contract(
        GAME_CONTRACT_ADDRESS,
        [
          "function balanceOf(address account, uint256 id) public view returns (uint256)",
        ],
        signer
      );
      let greenBalance = await contract.balanceOf(address, 1);
      let bonusBalance = await contract.balanceOf(address, 2);
      setOwnedBoxes({
        green: greenBalance.toString(),
        bonus: bonusBalance.toString(),
      });
      setAddress(address);
      setWallet(signer);
    } catch (e) {
      toast.error("Please connect wallet !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const getPermitNonce = async (address) => {
    let contract = new ethers.Contract(
      OPTIMATES_WETH,
      ["function nonces(address owner) public view virtual returns (uint256)"],
      wallet
    );
    let result = await contract.nonces(address);
    return result;
  };

  const getPermitSignature = async (value) => {
    // let contract = new ethers.Contract(
    //   OPTIMATES_WETH,
    //   ["function name() public view returns (string)"],
    //   wallet
    // );
    // let name = await contract.name();

    try {
      const domain = {
        name: TOKEN_NAME, // TODO: Change to your token name
        version: "1",
        chainId: 97,
        verifyingContract: OPTIMATES_WETH, // TODO: Change to your contract address
      };

      const types = {
        Permit: [
          {
            name: "owner",
            type: "address",
          },
          {
            name: "spender",
            type: "address",
          },
          {
            name: "value",
            type: "uint256",
          },
          {
            name: "nonce",
            type: "uint256",
          },
          {
            name: "deadline",
            type: "uint256",
          },
        ],
      };

      let nonce = await getPermitNonce(address);

      const values = {
        owner: address,
        spender: OPTIMATES_ADDRESS,
        value: ethers.utils.parseEther(value.toString()).toString(),
        nonce: nonce ?? "0", //
        deadline: ethers.utils.parseEther("1000000000000").toString(),
      };

      const signature = await wallet._signTypedData(domain, types, values);
      return signature;
    } catch (e) {
      toast.error("Could not get permission !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const prepareDatas = async () => {
    let queryParameters = `sender=${address}&bonus=${bonusSelected ? 2 : 1}`;
    let rawTransactionData = await axios
      .get(PUBLIC_API_URL + "/getGameTransaction?" + queryParameters)
      .then((res) => res.data);
    if (!rawTransactionData?.success) {
      toast.error("Could not get transaction data from backend", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    rawTransactionData = rawTransactionData.data;

    if (rawTransactionData.transactionType == "game_recorded") {
      setGameSessionId(rawTransactionData.gameSessionId);
      setLoading(false);
      return;
    }

    // TODO: Call sign and make data ready
    let signature = await getPermitSignature(5);
    if (!signature) {
      toast.error("Please sign the permit request", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    const sig = ethers.utils.splitSignature(signature);

    let permitData = {
      owner: address,
      spender: OPTIMATES_ADDRESS,
      value: ethers.utils.parseEther("5").toString(),
      deadline: ethers.utils.parseEther("1000000000000").toString(),
      v: sig.v,
      r: sig.r,
      s: sig.s,
    };
    // let collectInfoGetter = new ethers.utils.Interface([collectInfoAbi]);
    let key2 = bonusSelected ? 2 : 1;
    // let collectInfoCallData = collectInfoGetter.encodeFunctionData(
    //   "collectInfo",
    //   [address, key2]
    // );
    let game_contract = new ethers.Contract(
      GAME_CONTRACT_ADDRESS,
      collectInfoAbi,
      wallet
    );
    let cInfoData = await game_contract.populateTransaction.collectInfo(
      address,
      key2
    );

    console.log("CInfoData : ", cInfoData.data);

    const abiCoder = new ethers.utils.AbiCoder();
    let encodedCompareData = abiCoder.encode(["uint256"], [1]);
    console.log("EncodedCompareData : ", encodedCompareData);
    let compare = {
      callAddress: GAME_CONTRACT_ADDRESS,
      callData: "0x00" ?? cInfoData.data,
      compareData: encodedCompareData,
      types: [0],
      actions: [2],
    };

    // let contract = new ethers.Contract(
    //   GAME_CONTRACT_ADDRESS,
    //   [collectInfoAbi],
    //   wallet
    // );
    // let res = await contract.collectInfo(address, key2);
    // console.log("Collect Info : ", res);
    // console.log("CollectInfoCallData : ", collectInfoCallData);

    let eoaData = abiCoder.encode(
      [
        "tuple(address owner, address spender, uint256 value, uint256 deadline, uint8 v, bytes32 r, bytes32 s)",
        "tuple(address callAddress, bytes callData, bytes compareData, uint256[] types, uint256[] actions)",
        "bool",
      ],
      [permitData, compare, true]
    );
    console.log(eoaData);
    const txDomain = {
      name: "Entrypoint",
      version: "1",
      chainId: 97, // TODO: Chain id will be different for different networks
      verifyingContract: OPTIMATES_ADDRESS,
    };
    const types = {
      UserOperation: [
        { name: "sender", type: "address" },
        { name: "to", type: "address" },
        { name: "nonce", type: "uint256" },
        { name: "callData", type: "bytes" },
        { name: "accountGasLimits", type: "bytes32" },
        { name: "preVerificationGas", type: "uint256" },
        { name: "gasFees", type: "bytes32" },
        { name: "eoaPayment", type: "bytes" },
        { name: "paymasterAndData", type: "bytes" },
      ],
    };
    console.log("rawTransactionData", rawTransactionData);
    const values = {
      sender: address,
      to: GAME_CONTRACT_ADDRESS,
      nonce: BigNumber.from(rawTransactionData?.nonce) ?? 0,
      callData: rawTransactionData.callData,
      accountGasLimits: rawTransactionData.accountGasLimits,
      preVerificationGas: rawTransactionData?.preVerificationGas ?? 400000,
      gasFees: rawTransactionData.gasFees,
      eoaPayment: eoaData,
      paymasterAndData: rawTransactionData?.paymasterAndData ?? "",
    };
    console.log("txDomain", txDomain, types, values);
    const signatureData = await wallet._signTypedData(txDomain, types, values);
    values.signature = signatureData;
    values.boxId = bonusSelected ? 2 : 1;
    // TODO: Send signed data to backend
    let result = await axios.post(PUBLIC_API_URL + "/startGameSession", {
      signedTx: values,
    });

    console.log("Result : ", result);
    setGameSessionId(result?.data?.data?.gameSessionId);
    setLoading(false);
  };

  const handleGameStart = async () => {
    setLoading(true);
    await prepareDatas();
    setGreenCount(0);
    setBonusCount(0);
    setGameState(1);
  };

  const changeChain = async () => {
    try {
      if (chainId !== 97) {
        //@ts-ignore
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x61" }]
        });
      }
    } catch (err) {

    }
  }


  const handlePlayAgain = async () => {
    setClaimPhase("Claiming...");
    let gameOver = await axios.get(
      PUBLIC_API_URL +
      `/gameOver?sender=${address}&green=${greenCount}&bonus=${bonusCount}`
    );
    if (!gameOver?.data?.success) {
      toast.error("Could not end game", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
      return;
    }
    let claimRewards = await axios.get(
      PUBLIC_API_URL + `/claim?gameSessionId=${gameSessionId}`
    );
    if (!claimRewards?.data?.success) {
      toast.error("Could not claim rewards", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
      return;
    } else {
      toast.success("Rewards claimed successfully !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    }
    setGreenCount(0);
    setBonusCount(0);
    setBonusSelected(false);
    setGameSessionId("");
    setGameState(0);
  };

  const OwnedBoxes = () => {
    return (
      <div className="owned-boxes">
        <span>Owned boxes: </span>
        <div className="box">
          <div className="square green"></div>
          <span>{ownedBoxes?.green ?? "0"}</span>
        </div>
        <div className="box">
          <div className="square purple"></div>
          <span>{ownedBoxes?.bonus ?? "0"}</span>
        </div>
      </div>
    );
  };
  const SquareSelector = () => {
    const squareStyle = (color, isSelected) => ({
      width: "250px",
      height: "250px",
      backgroundColor: color,
      margin: "10px",
      display: "inline-block",
      cursor: "pointer",
      boxShadow: isSelected ? "0 0 50px 5px rgba(0, 0, 0, 0.5)" : "",
      border: isSelected ? "5px solid black" : "",
    });

    return (
      <div>
        <div
          style={squareStyle("#7cfc00", !bonusSelected)}
          onClick={() => setBonusSelected(false)}
        ></div>
        <div
          style={squareStyle("#f200ff", bonusSelected)}
          onClick={() => setBonusSelected(true)}
        ></div>
      </div>
    );
  };

  const Game = ({ gs }) => {
    switch (gs) {
      case 0:
        return (
          <>
            <h2>Select Boxes to Mint</h2> <OwnedBoxes />{" "}
            <h2>{address ?? "Please connect wallet"}</h2> <SquareSelector />
            <button
              style={{ marginTop: 15 }}
              className="gradient-button"
              disabled={loading}
              onClick={handleGameStart}
            >{`${loading ? "Loading" : "Play !"}`}</button>
            <div className="flex w-full justify-around mt-5">
              <button
                className="faucetButton"
                onClick={() => mintWeth()}
              >
                Mint Weth
              </button>
            </div>
          </>
        );
      case 1:
        return (
          <Board
            bonus={bonusCount}
            setBonus={setBonusCount}
            green={greenCount}
            setGreen={setGreenCount}
            gameState={gs}
            address={address}
            setGameState={setGameState}
          ></Board>
        );
      case 2:
        return (
          <div>
            <h1>Game Ended</h1>
            <p>Collected {greenCount} green box</p>
            <p>Collected {bonusCount} bonus box</p>
            <button
              className="gradient-button"
              disabled={claimPhase !== "Claim & Play Again!"}
              onClick={handlePlayAgain}
            >
              {claimPhase}
            </button>
          </div>
        );
    }
  };

  const mintWeth = async () => {
    let contract = new ethers.Contract(
      OPTIMATES_WETH,
      [
        "function deposit() external payable",
        "function balanceOf(address user) external view returns(uint256)"
      ],
      wallet
    );

    const ethereum = window.ethereum;

    let provider = new ethers.providers.Web3Provider(ethereum);

    const etherBalance = await provider.getBalance(address);

    if (etherBalance.lt(ethers.utils.parseEther("0.15"))) {
      toast.error("You need to have at least 0.15 ether to mint Weth", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      return;
    }

    await contract.deposit({ value: ethers.utils.parseEther("0.1") });
  }

  return (
    <div className="App">
      {address ? (
        <Game gs={gameState}></Game>
      ) : (
        <>
          <div>
            <button className="gradient-button" onClick={chainId === 97 ? handleConnectWallet : changeChain} >
              {chainId === 97 ? <p>Connect Wallet</p> : <p>Change Chain</p>}
            </button>
          </div>
        </>

      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
