import React, { useEffect, useState } from "react";
import { randomIntFromInterval, useInterval } from "../lib/utils.js";

import "./Board.css";
class LinkedListNode {
  constructor(value) {
    this.value = value;
    this.next = null;
  }
}

class LinkedList {
  constructor(value) {
    const node = new LinkedListNode(value);
    this.head = node;
    this.tail = node;
  }
}

const Direction = {
  UP: "UP",
  RIGHT: "RIGHT",
  DOWN: "DOWN",
  LEFT: "LEFT",
};

const ZEMIN_BOYUT = 15;
const PROBABILITY_OF_DIRECTION_REVERSAL_FOOD = 0.6;

const yilanBaslangicNoktasiGetir = (zemin) => {
  const rowSize = zemin.length; // Row sayısı (boyuna)
  const colSize = zemin[0].length; // İlk row'un column sayısı (enine)
  const startingRow = Math.round(rowSize / 3); // row sayisini 3'e bol, sonucu yuvarla
  const startingCol = Math.round(colSize / 3); // column sayisini 3'e bol, sonucu yuvarla
  const startingCell = zemin[startingRow][startingCol];
  return {
    row: startingRow,
    col: startingCol,
    cell: startingCell,
  };
};

const Board = ({
  setGameState,
  gameState,
  address,
  bonus,
  setBonus,
  green,
  setGreen,
}) => {
  const [skor, skorAyarla] = useState(0);
  const [zemin, zeminAyarla] = useState(createTiles(ZEMIN_BOYUT));
  const [bonusCount, setBonusCount] = useState(0);
  const [greenCount, setGreenCount] = useState(0);
  const [yilan, yilanAyarla] = useState(
    new LinkedList(yilanBaslangicNoktasiGetir(zemin))
  );
  const [yilanKutulari, yilanKutulariAyarla] = useState(
    new Set([yilan.head.value.cell])
  );
  const [yemekKutusu, yemekKutusuAyarla] = useState(yilan.head.value.cell + 5);
  const [yon, yonAyarla] = useState(Direction.RIGHT);
  const [yemekTersYondeOlmali, yemekTersYondeOlmaliAyarla] = useState(false);
  const [bonusMiktari, bonusMiktariAyarla] = useState(10);
  const [bonusUyarisi, bonusUyarisiniAyarla] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      handleKeydown(e);
    });
  }, []);

  useInterval(() => {
    moveSnake();
  }, 150);

  const handleKeydown = (e) => {
    const yeniYon = keyPressDirection(e.key);
    const gecerliBirYonMu = yeniYon !== "";
    if (!gecerliBirYonMu) return;
    const yilanKendiUzerineCikti =
      oppositeDirection(yeniYon) === yon && yilanKutulari.size > 1;
    if (yilanKendiUzerineCikti) return;
    yonAyarla(yeniYon);
  };

  const moveSnake = () => {
    const suankiKafaKordinatlari = {
      row: yilan.head.value.row,
      col: yilan.head.value.col,
    };

    const birSonrakiKafaKordinatlari = getDirectionCoordinates(
      suankiKafaKordinatlari,
      yon
    );
    if (isOutOfBoundry(birSonrakiKafaKordinatlari, zemin)) {
      gameOver();
      return;
    }

    const birSonrakiKafaKutusu =
      zemin[birSonrakiKafaKordinatlari.row][birSonrakiKafaKordinatlari.col];
    if (yilanKutulari.has(birSonrakiKafaKutusu)) {
      gameOver();
      return;
    }

    const yeniKafa = new LinkedListNode({
      row: birSonrakiKafaKordinatlari.row,
      col: birSonrakiKafaKordinatlari.col,
      cell: birSonrakiKafaKutusu,
    });

    const suankiKafa = yilan.head;
    yilan.head = yeniKafa;
    suankiKafa.next = yeniKafa;

    const yeniYilanKutulari = new Set(yilanKutulari);
    yeniYilanKutulari.delete(yilan.tail.value.cell);
    yeniYilanKutulari.add(birSonrakiKafaKutusu);

    yilan.tail = yilan.tail.next;
    if (yilan.tail === null) yilan.tail = yilan.head;

    const yemekTuketildi = birSonrakiKafaKutusu === yemekKutusu;
    if (yemekTuketildi) {
      growSnake(yeniYilanKutulari);
      if (yemekTersYondeOlmali) {
        consumeBait(yeniYilanKutulari, true);
      } else {
        consumeBait(yeniYilanKutulari, false);
      }
    }

    yilanKutulariAyarla(yeniYilanKutulari);
  };

  const growSnake = (yeniYilanKutulari) => {
    const nodetGrow = nodesToGrow(yilan.tail, yon);
    if (isOutOfBoundry(nodetGrow, zemin)) {
      // daha buyuyecek yer kalmadi demek oluyor.
      return;
    }
    const yeniKuyrukKutusu = zemin[nodetGrow.row][nodetGrow.col];
    const yeniKuyruk = new LinkedListNode({
      row: nodetGrow.row,
      col: nodetGrow.col,
      cell: yeniKuyrukKutusu,
    });
    const suankiKuyruk = yilan.tail;
    yilan.tail = yeniKuyruk;
    yilan.tail.next = suankiKuyruk;

    yeniYilanKutulari.add(yeniKuyrukKutusu);
  };

  const nodesToGrow = (yilanKuyruk, suankiYon) => {
    const kuyrukBirSonrakiNodeYonu = nextDirectionNode(yilanKuyruk, suankiYon);
    const buyumeYonu = oppositeDirection(kuyrukBirSonrakiNodeYonu);
    const suankiKuyrukKordinatlari = {
      row: yilanKuyruk.value.row,
      col: yilanKuyruk.value.col,
    };
    const buyuyecekNodeKordinatlari = getDirectionCoordinates(
      suankiKuyrukKordinatlari,
      buyumeYonu
    );
    return buyuyecekNodeKordinatlari;
  };

  const consumeBait = (yeniYilanKutulari, bonus) => {
    const maksOlasiKutuValue = ZEMIN_BOYUT * ZEMIN_BOYUT;

    let birSonrakiYemekKutusu;

    while (true) {
      birSonrakiYemekKutusu = randomIntFromInterval(1, maksOlasiKutuValue);
      if (
        yeniYilanKutulari.has(birSonrakiYemekKutusu) ||
        yemekKutusu === birSonrakiYemekKutusu
      ) {
        continue;
      } else {
        break;
      }
    }

    const birSonrakiYemekTersYondeOlmali =
      Math.random() < PROBABILITY_OF_DIRECTION_REVERSAL_FOOD;

    yemekKutusuAyarla(birSonrakiYemekKutusu);
    yemekTersYondeOlmaliAyarla(birSonrakiYemekTersYondeOlmali);
    if (bonus) {
      skorAyarla(skor + bonusMiktari);
      setBonusCount(bonusCount + 1);
      bonusUyarisiniAyarla(true);
      setTimeout(() => {
        bonusUyarisiniAyarla(false);
      }, 2000);
    } else {
      setGreenCount(greenCount + 1);
      skorAyarla(skor + 1);
    }
  };

  const nextDirectionNode = (node, suankiYon) => {
    if (node.next === null) return suankiYon;

    const { row: currentRow, col: currentCol } = node.value;
    const { row: nextRow, col: nextCol } = node.next.value;
    if (nextRow === currentRow && nextCol === currentCol + 1) {
      return Direction.RIGHT;
    }
    if (nextRow === currentRow && nextCol === currentCol - 1) {
      return Direction.LEFT;
    }
    if (nextCol === currentCol && nextRow === currentRow + 1) {
      return Direction.DOWN;
    }
    if (nextCol === currentCol && nextRow === currentRow - 1) {
      return Direction.UP;
    }
    return "";
  };

  const keyPressDirection = (key) => {
    const keyLC = key.toLowerCase();
    if (keyLC === "arrowup" || keyLC === "w") {
      return Direction.UP;
    } else if (keyLC === "arrowright" || keyLC === "d") {
      return Direction.RIGHT;
    } else if (keyLC === "arrowdown" || keyLC === "s") {
      return Direction.DOWN;
    } else if (keyLC === "arrowleft" || keyLC === "a") {
      return Direction.LEFT;
    } else {
      return "";
    }
  };

  const oppositeDirection = (key) => {
    const keyLC = key.toLowerCase();
    if (keyLC === "arrowup" || keyLC === "w") {
      return Direction.DOWN;
    } else if (keyLC === "arrowright" || keyLC === "d") {
      return Direction.LEFT;
    } else if (keyLC === "arrowdown" || keyLC === "s") {
      return Direction.UP;
    } else if (keyLC === "arrowleft" || keyLC === "a") {
      return Direction.RIGHT;
    } else {
      return "";
    }
  };

  const getDirectionCoordinates = (kordinatlar, yon) => {
    let geciciRow = kordinatlar.row;
    let geciciCol = kordinatlar.col;
    if (yon === Direction.UP) {
      geciciRow -= 1;
    } else if (yon === Direction.RIGHT) {
      geciciCol += 1;
    } else if (yon === Direction.DOWN) {
      geciciRow += 1;
    } else if (yon === Direction.LEFT) {
      geciciCol -= 1;
    }
    return {
      row: geciciRow,
      col: geciciCol,
    };
  };

  const gameOver = async () => {
    skorAyarla(0);
    setBonus(bonusCount);
    setGreen(greenCount);
    setGameState(2);
    // const yilanBaslangicNoktasi = yilanBaslangicNoktasiGetir(zemin);
    // yilanAyarla(new LinkedList(yilanBaslangicNoktasi));
    // yemekKutusuAyarla(yilanBaslangicNoktasi.cell + 5);
    // yilanKutulariAyarla(new Set([yilanBaslangicNoktasi.cell]));
    // yonAyarla(Direction.RIGHT);
  };

  return (
    <>
      <h1>Score: {skor}</h1>
      <h3>{address}</h3>
      <div className="board">
        {zemin.map((row, rowIdx) => (
          <div key={rowIdx} className="row">
            {row.map((cellValue, cellIdx) => {
              const className = getClassName(
                cellValue,
                yemekKutusu,
                yemekTersYondeOlmali,
                yilanKutulari
              );
              return <div key={cellIdx} className={className}></div>;
            })}
          </div>
        ))}
      </div>
      <div className="bonus-msg">
        <p>
          {bonusUyarisi
            ? `Earned ${bonusMiktari} amount of bonus!`
            : "Don't touch to walls!"}
        </p>
      </div>
    </>
  );
};

const createTiles = (ZEMIN_BOYUT) => {
  let sayac = 1;
  const zemin = [];

  for (let row = 0; row < ZEMIN_BOYUT; row++) {
    const suankiRow = [];
    for (let col = 0; col < ZEMIN_BOYUT; col++) {
      suankiRow.push(sayac++);
    }
    zemin.push(suankiRow);
  }
  return zemin;
};

const isOutOfBoundry = (kordinatlar, zemin) => {
  const { row, col } = kordinatlar;
  if (row < 0 || col < 0) return true;
  if (row >= zemin.length || col >= zemin[0].length) return true;
  return false;
};

const getClassName = (
  cellValue,
  foodCell,
  foodShouldReverseDirection,
  snakeCells
) => {
  let className = "cell";
  if (cellValue === foodCell) {
    if (foodShouldReverseDirection) {
      className = "cell cell-purple";
    } else {
      className = "cell cell-green";
    }
  }
  if (snakeCells.has(cellValue)) className = "cell cell-snake";

  return className;
};

export default Board;
